import React from "react"
import { Link } from "gatsby"


export default function Footer() {
  return (
    <footer>
      <div>
        Copyright &copy; 2021 ayumu All rights reserved.
      </div>
    </footer>
  )
}

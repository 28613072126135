import React from "react"
import { css } from "@emotion/react"
// import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

import Footer from "./Footer";
import Header from "./Header";

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 700px;
        padding: ${rhythm(2)};
        padding-top: ${rhythm(1.5)};
      `}
    >
      <Header />
      {children}
      <Footer />
    </div>
  )
}
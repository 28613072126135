import React from "react"
import { css } from "@emotion/react"
import { useStaticQuery, Link, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

export default function Header() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  return (
    <header>
      <h1
        css={css`
            margin-bottom: ${rhythm(2)};
            display: inline-block;
            font-style: normal;
          `}>
        <Link to={"/"} css={css`
                text-decoration: none;
                color: inherit;
              `}
        >{data.site.siteMetadata.title}</Link>
      </h1>
      <p>{data.site.siteMetadata.description}</p>

    </header>
  )
}
